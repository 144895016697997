import {
  AppShell,
  rem,
  Flex,
  Button,
  Burger,
  Box,
  Group,
  Text,
  Stack,
  Menu,
  NavLink,
} from "@mantine/core";
import { useTranslation } from "next-i18next";
import {
  IconChevronDown,
  IconPhone,
  IconTicket,
  IconWorld,
} from "@tabler/icons-react";
import Link from "next/link";
import Image from "next/image";
import AppLogo from "../../../public/logo.svg";
import AppTSELogo from "../../../public/logo-tse.svg";
import { getAssetUrl } from "@/utils";

export function Header({
  openLanguageModal,
  openCurrencyModal,
  selectedCurrency,
  opened,
  toggle,
}: {
  openLanguageModal: () => void;
  openCurrencyModal: () => void;
  toggle: () => void;
  opened: boolean;
  selectedCurrency?: string;
}): JSX.Element {
  const { t, i18n, ready } = useTranslation();
  const guideLinks = t("common:guide", { returnObjects: true }) as any;

  return (
    <AppShell.Header className="shadow-xl px-2.5 lg:px-0 bg-blue-9 top-0 fixed h-17">
      <Group className="mx-auto my-0 max-w-[1350px] justify-between px-4 h-full gap-1">
        <Flex justify="space-between" w="100%" align="center" h="100%">
          <Flex className="h-full items-center justify-start">
            <Box
              hiddenFrom="lg"
              className="h-full mr-2"
              component={Link}
              href="/"
              locale={i18n.language}
              shallow={true}
            >
              <AppLogo alt={t("meta:home.title")} width={187} height={70} />
            </Box>
            <Box
              visibleFrom="lg"
              className="h-full"
              component={Link}
              href="/"
              locale={i18n.language}
              shallow={true}
            >
              <AppTSELogo alt={t("meta:home.title")} width={371} height={70} />
            </Box>
          </Flex>
          <Button
            variant="filled"
            color="white"
            className="text-blue-9 uppercase"
            fw={400}
            h={rem(46)}
            w={rem(70)}
            px={0}
            hiddenFrom="sm"
            component="a"
            href={`${process.env.NEXT_PUBLIC_NAVITIME_SUBMIT_URL}/${i18n.language}/booking/jr/mypage/login`}
          >
            <Stack gap={0} align="center">
              <IconTicket height={20} width={20} />
              <Text size={rem(10)} className="whitespace-break-spaces">
                {t("common:links.my_orders")}
              </Text>
            </Stack>
          </Button>
          <Button
            variant="subtle"
            color="dark.0"
            fw={400}
            h={rem(30)}
            hiddenFrom="sm"
            classNames={{
              root: "text-white uppercase px-1 rounded-full hover:bg-blue-7",
              section: "ml-0.5",
            }}
            onClick={openLanguageModal}
          >
            <IconWorld height={26} width={26} className="stroke-1" />
          </Button>
          <Flex gap="xs" visibleFrom="md" align="center" ml={0}>
            <Flex>
              <Link href="/" locale={i18n.language} shallow={true}>
                <Button
                  variant="subtle"
                  color="dark.0"
                  fw={400}
                  px="xs"
                  className="uppercase text-white rounded-full hover:bg-blue-7"
                >
                  {t("common:links.home")}
                </Button>
              </Link>
              <Menu shadow="md" width={rem(250)}>
                <Menu.Target>
                  <Button
                    variant="subtle"
                    color="dark.0"
                    fw={400}
                    px="xs"
                    classNames={{
                      root: "uppercase text-white rounded-full hover:bg-blue-7",
                      section: "ml-1",
                    }}
                    rightSection={
                      <IconChevronDown
                        height={16}
                        width={16}
                        strokeWidth={1.5}
                      />
                    }
                  >
                    {t("common:links.boarding_guide")}
                  </Button>
                </Menu.Target>

                {ready && (
                  <Menu.Dropdown>
                    {Array.isArray(guideLinks) && guideLinks?.map((link: any, index: number) => (
                      <Box key={index} px="xs">
                        {link.subcontent ? (
                          <NavLink component="button" label={t(link.title)}>
                            {link?.subcontent?.map(
                              (content: any, index: number) => (
                                <Link
                                  key={index}
                                  href={`/guide/${link.url}/${content.url}`}
                                  locale={i18n.language}
                                  shallow={true}
                                >
                                  <NavLink
                                    component="button"
                                    label={content.title}
                                  />
                                </Link>
                              )
                            )}
                          </NavLink>
                        ) : (
                          <Link
                            href={`/guide/${link.url}`}
                            locale={i18n.language}
                            shallow={true}
                          >
                            <NavLink component="button" label={t(link.title)} />
                          </Link>
                        )}
                      </Box>
                    ))}
                  </Menu.Dropdown>
                )}
              </Menu>

              <Link href="/faq" locale={i18n.language} shallow={true}>
                <Button
                  variant="subtle"
                  color="dark.0"
                  fw={400}
                  px="xs"
                  className="uppercase text-white rounded-full hover:bg-blue-7"
                >
                  {t("common:links.faq")}
                </Button>
              </Link>
              <Link href="/contact" locale={i18n.language} shallow={true}>
                <Button
                  variant="subtle"
                  color="dark.0"
                  fw={400}
                  px="xs"
                  className="uppercase text-white rounded-full hover:bg-blue-7"
                >
                  {t("common:links.contact")}
                </Button>
              </Link>
            </Flex>
            <Button
              variant="filled"
              color="white"
              className="text-blue-9 uppercase"
              fw={400}
              h={rem(46)}
              leftSection={<IconTicket height={16} width={16} />}
              component="a"
              href={`${process.env.NEXT_PUBLIC_NAVITIME_SUBMIT_URL}/${i18n.language}/booking/jr/mypage/login`}
            >
              {t("common:links.my_orders")}
            </Button>
            <Link href={`tel:${t("common:service.contact")}`} shallow={true}>
              <Stack
                gap={0}
                px={rem(15)}
                py={rem(5)}
                align="center"
                className="border border-solid border-white rounded-md"
                visibleFrom="lg"
              >
                <Group gap={rem(5)}>
                  <IconPhone className="text-white" height={16} width={16} />
                  <Text c="white" size="sm">
                    {t("common:service.contact")}
                  </Text>
                </Group>

                <Text c="white" size="xs">
                  {t("common:service.business_hours")}
                </Text>
              </Stack>
            </Link>
            <Button
              variant="subtle"
              color="dark.0"
              fw={400}
              h={rem(30)}
              rightSection={<IconChevronDown height={16} width={16} />}
              classNames={{
                root: "text-white uppercase px-1 rounded-full hover:bg-blue-7",
                section: "ml-0.5",
              }}
              onClick={openLanguageModal}
            >
              <IconWorld height={20} width={20} className="stroke-1" />
            </Button>
            <Button
              variant="subtle"
              color="dark.0"
              fw={400}
              w={"5rem"}
              h={rem(30)}
              rightSection={<IconChevronDown height={16} width={16} />}
              classNames={{
                root: "text-white uppercase px-1 rounded-full hover:bg-blue-7",
                section: "ml-0.5",
              }}
              onClick={openCurrencyModal}
            >
              {selectedCurrency}
            </Button>
          </Flex>
          <Burger
            opened={opened}
            onClick={toggle}
            hiddenFrom="md"
            size="md"
            color="white"
          />
        </Flex>
      </Group>
    </AppShell.Header>
  );
}

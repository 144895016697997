import { IBreadcrumbs } from "@/types";
import { useDeviceQuery } from "@/utils";
import {
  Anchor,
  Box,
  BoxProps,
  Breadcrumbs,
  Container,
  Text,
  rem,
} from "@mantine/core";
import Link from "next/link";

interface Props extends BoxProps {
  items?: IBreadcrumbs[];
  hasNavbar?: boolean;
}

export function FooterBreadcrumbs({
  items,
  hasNavbar,
  ...rest
}: Props): JSX.Element {
  const [isMobile] = useDeviceQuery();
  const path = items?.map((item: IBreadcrumbs) => {
    if (item.href !== "") {
      return (
        <Anchor
          component={Link}
          href={item.href}
          key={item.title}
          c="dark.7"
          size="sm"
        >
          {item.title}
        </Anchor>
      );
    } else {
      return (
        <Text key={item.title} c="dark.7" size="sm">
          {item.title}
        </Text>
      );
    }
  });

  return (
    <Box ml={{ base: 0, md: hasNavbar ? rem(280) : 0 }} {...rest}>
      <Container size={!isMobile && hasNavbar ? "fluid" : "xl"} py="md">
        <Breadcrumbs
          separator=">"
          classNames={{ root: "flex-wrap gap-2", separator: "text-dark-7" }}
          separatorMargin={0}
        >
          {path}
        </Breadcrumbs>
      </Container>
    </Box>
  );
}

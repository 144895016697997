import { AppShell, Box, NavLink } from "@mantine/core";
import { t } from "i18next";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { usePathname } from "next/navigation";

export function Navbar({
  navIcons,
}: {
  navIcons: { [key: string]: React.ReactNode };
}): JSX.Element {
  const { t, i18n, ready } = useTranslation();
  const guideLinks = t("common:guide", { returnObjects: true }) as any;
  const pathname = usePathname();

  const isActiveUrl = (url: string) => {
    return pathname.includes(url);
  };

  return (
    <AppShell.Navbar p="md" className="bg-dark-4">
      {ready &&
        guideLinks?.map((link: any, index: number) => (
          <Box key={index} px="xs">
            {link.subcontent ? (
              <NavLink
                component="button"
                leftSection={navIcons[link.url]}
                classNames={{
                  section: "text-blue-9",
                  label: "text-dark-8",
                  children: `border border-solid border-r-0 border-b-0 border-t-0
                           border-blue-3 p-0 ml-6 text-dark-8`,
                }}
                label={t(link.title)}
                defaultOpened={isActiveUrl(link.url)}
              >
                {link.subcontent?.map((content: any, index: number) => (
                  <Link
                    key={index}
                    href={`/guide/${link.url}/${content.url}`}
                    locale={i18n.language}
                    shallow={true}
                  >
                    <NavLink
                      component="button"
                      active={isActiveUrl(content.url)}
                      key={index}
                      label={content.title}
                      className={`data-[active]:bg-transparent data-[active]:font-bold 
                                data-[active]:text-orange-9`}
                    />
                  </Link>
                ))}
              </NavLink>
            ) : (
              <Link
                href={`/guide/${link.url}`}
                locale={i18n.language}
                shallow={true}
              >
                <NavLink
                  component="button"
                  leftSection={navIcons[link.url]}
                  label={t(link.title)}
                  active={isActiveUrl(link.url)}
                  classNames={{
                    section: "text-blue-9",
                    root: `data-[active]:bg-transparent data-[active]:font-bold 
                         data-[active]:text-orange-9 text-dark-8`,
                  }}
                />
              </Link>
            )}
          </Box>
        ))}
    </AppShell.Navbar>
  );
}

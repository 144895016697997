import { TYPE } from "@/constants/locale";
import { Button, Modal, SimpleGrid, rem, Text, Stack } from "@mantine/core";
import { useTranslation } from "next-i18next";
import { getCookie, setCookie } from "cookies-next";
import { useRouter } from "next/router";
import { useState } from "react";
import { useDeviceQuery } from "@/utils";
import { IconCircleCheckFilled } from "@tabler/icons-react";

export function SelectModal({
  opened,
  close,
  data,
  dataType,
}: {
  opened: boolean;
  close: () => void;
  data: any;
  dataType: number;
}): JSX.Element {
  const [isMobile] = useDeviceQuery();
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const router = useRouter();
  const { pathname, asPath, query } = router;
  const [currency, setCurrency] = useState<string>(
    getCookie("currency") || "JPY"
  );

  const handleSwitch = (selected: string) => {
    if (dataType === TYPE.LANG) {
      router
        .push({ pathname, query }, asPath, { locale: selected })
        .then(() => {
          router.reload();
        });
      close();
    } else if (dataType === TYPE.CURRENCY) {
      setCurrency(selected);
      setCookie("currency", selected);
      router.reload();
      close();
    }
  };

  const displayTitle = () => {
    if (dataType === TYPE.LANG) {
      return "language";
    } else if (dataType === TYPE.CURRENCY) {
      return "currency";
    }
  };

  return (
    <Modal
      size={rem(800)}
      opened={opened}
      onClose={close}
      centered
      radius={isMobile ? 0 : "md"}
      classNames={{
        body: `${isMobile && "pt-1 px-0"} pb-10`,
        header: `${
          isMobile
            ? `border-b border-r-0 border-l-0 border-t-0 border-solid 
          border-dark-5 py-5`
            : `py-7`
        } block text-center`,
        close: `absolute ${isMobile ? "right-2" : "left-2"} top-3.5`,
      }}
      fullScreen={isMobile}
      title={isMobile && t(`common:header.${displayTitle()}`)}
    >
      <SimpleGrid cols={isMobile ? 1 : 4} verticalSpacing={isMobile ? 0 : "md"}>
        {data?.map((item: any) => (
          <Button
            key={item.key}
            variant="subtle"
            h="auto"
            classNames={{
              root: `border border-solid ${
                !isMobile && (currency === item.key || lang === item.key)
                  ? "border-dark-9"
                  : ""
              } py-1 hover:bg-dark-4 ${
                isMobile &&
                `border-l-0 border-r-0 border-t-0 rounded-none 
                border-dark-5 py-3`
              }`,
              inner: "items-center justify-between",
            }}
            onClick={() => handleSwitch(item.key)}
            rightSection={
              isMobile &&
              (currency === item.key || lang === item.key) && (
                <IconCircleCheckFilled />
              )
            }
          >
            <Stack gap={0}>
              <Text ta="left" c="dark.9">
                {item.label}
              </Text>
              {dataType === TYPE.CURRENCY && (
                <Text ta="left" c="dark.7">
                  {item.key + " - " + item.symbol}
                </Text>
              )}
            </Stack>
          </Button>
        ))}
      </SimpleGrid>
    </Modal>
  );
}
